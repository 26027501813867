<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      disable-pagination
      disable-sort
    >
      <!-- <template v-slot:[`item.description`]="{ item }">
        <div v-if="item.haber !== null">
          {{ item.description }}
        </div>
      </template> -->
      <template v-slot:[`item.debe`]="{ item }">
        <div v-if="item.debe !== null">
          {{ item.debe | currency }}
        </div>
      </template>
      <template v-slot:[`item.haber`]="{ item }">
        <div v-if="item.haber !== null">
          {{ item.haber | currency }}
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="text-center">
          <v-icon medium color="blue darken-2" @click="edit(item.index)">
            mdi-file-document-edit-outline
          </v-icon>
          <v-icon
            medium
            color="red darken-2"
            v-if="item.code !== ''"
            @click="erase(item.index)"
          >
            mdi-trash-can-outline
          </v-icon>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="editDialog" persistent>
      <v-card>
        <v-alert
          v-if="error.length != 0"
          border="left"
          color="red"
          elevation="24"
          type="error"
        >
          <span>{{ error[0] }}</span>
        </v-alert>
        <v-card-title>Editar Partida</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="isValid" :lazy-validation="false">
            <v-row ref="form">
              <v-col cols="2">
                <v-text-field
                  label="Cuenta"
                  v-model="item.code"
                  :rules="onlyRequired"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Descripción"
                  v-model="item.description"
                  :rules="onlyRequired"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  label="Sucursal"
                  v-model="item.sucursal"
                  :rules="onlyRequired"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Debe"
                  v-model="item.debe"
                  :rules="decimalRules"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Haber"
                  v-model="item.haber"
                  :rules="decimalRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="closeDialog()"> Cerrar </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveAccountingItem()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import rules from "@/utils/form.rules.js";
export default {
  props: {
    resetForm: { type: Boolean },
  },
  data: () => ({
    editDialog: false,
    isValid: false,
    error: [],
    currentIndex: 0,
    editIndex: null,
    headers: [
      { text: "Cuenta", value: "code" },
      { text: "Descripción de la Cuenta", value: "description" },
      { text: "Sucursal", value: "sucursal", align: "end", width: 50 },
      { text: "Debe", value: "debe", align: "end" },
      { text: "Haber", value: "haber", align: "end" },
      { text: "Acciones", value: "actions", aling: "center", width: 80 },
    ],
    item: {
      index: null,
      code: "",
      description: "",
      sucursal: "",
      debe: null,
      haber: null,
    },
    items: [
      {
        index: 0,
        code: "",
        description: "",
        sucursal: "",
        debe: 0,
        haber: 0,
      },
    ],

    //rules
    onlyRequired: [rules.required],
    decimalRules: [rules.decimal],
  }),
  methods: {
    edit(index) {
      this.editIndex = index;
      this.item = this.items[index];
      this.editDialog = true;
    },
    erase(index) {
      this.items.splice(index, 1);
      this.addedIndex();
    },
    saveAccountingItem() {
      if (this.$refs.form.validate()) {
        if (
          (this.item.debe != 0 && this.item.haber == 0) ||
          (this.item.debe == 0 && this.item.haber != 0)
        ) {
          this.items[this.editIndex] = this.item;
          this.item = {
            index: null,
            code: "",
            description: "",
            sucursal: "",
            debe: 0,
            haber: 0,
          };
          this.editDialog = false;
          this.editIndex = null;

          this.error = [];
          this.addedIndex();
        } else {
          this.error.push("El monto debe ir solo en Haber o en Debe");
          return false;
        }
      }
    },
    addedIndex() {
      this.items.forEach((value, index) => {
        if (this.items.length === index + 1 && value.code !== "") {
          this.items.push({
            index: index + 1,
            code: "",
            description: "",
            sucursal: "",
            debe: 0,
            haber: 0,
          });
        }
        value.index = index;
      });
    },
    closeDialog() {
      this.item = {
        index: null,
        code: "",
        description: "",
        sucursal: "",
        debe: 0,
        haber: 0,
      };
      this.editDialog = false;
      this.editIndex = null;
      this.error = [];
      if (this.items.length == 1) {
        this.items = [
          {
            index: 0,
            code: "",
            description: "",
            sucursal: "",
            debe: 0,
            haber: 0,
          },
        ];
      } else {
        this.items.pop();
      }

      this.addedIndex();
    },
    reset() {
      this.item = {
        index: null,
        code: "",
        description: "",
        sucursal: "",
        debe: 0,
        haber: 0,
      };
      this.items = [
        {
          index: 0,
          code: "",
          description: "",
          sucursal: "",
          debe: 0,
          haber: 0,
        },
      ];
      this.$refs.form.reset();
    },
  },
  watch: {
    resetForm: function (newValue) {
      if (newValue == true) {
        this.reset();
        // this.$emit("clearForm");
      }
    },
  },
};
</script>
